<template>
  <div class="leads_status"
       :style="
           {backgroundColor:
           status === 'red'? '#C0392B' :
           status === 'yellow'? '#F1C40F':'',
           color:
           status === 'yellow'? '#1C2833' : ''
           }"
  >
    <svg-icon name="solid/clock" width="12px"/>
    <p>{{dateFormat(time)}} </p>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import {formatDate, dateRemainFormat} from "../../helpers/datetime";

export default {
  name: 'LeadsStatus',
  props: ['time'],
  emits: ['expired'],
  setup(props, {emit}) {
    const status = ref(false)
    const newDate = ref(new Date)
    setInterval(() => {newDate.value = new Date}, 60000)
    let dateFormat = (date) => {
      let time = dateRemainFormat(date, newDate.value)
      if (time) {
        let dayText = time.diffDays != 1 ? 'days' : 'day'
        let hourText = time.diffHours != 1 ? 'hours' : 'hour'
        let minuteText = 'min'
        if(time.diffDays < 0 || time.diffHours< 0 || time.diffMinutes < 0) {
          emit('expired')
        }
        time.diffMinutes = Math.abs(time.diffMinutes)
        if (time.diffMinutes < 10) {time.diffMinutes = '0'+time.diffMinutes}
        if (time.diffDays > 0) {
          status.value = 'blue'
          return `${time.diffDays}d ${time.diffHours}h`
        } else if (time.diffDays == 0 && time.diffHours > 4) {
          status.value = 'yellow'
          return `${time.diffHours}:${time.diffMinutes}`
        } else {
          status.value = 'red'
          return `${time.diffHours}:${time.diffMinutes}`
        }
      }

    }
    return {
      dateFormat,
      status,
    }
  }
};
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.leads_status {
  border-radius: 2px;
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 13px;
  p {
    margin-left: 5px;
  }
  span {
    opacity: 0.6;
  }
  background-color: #0B3553;
  color: white;
}

</style>
