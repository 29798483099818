<template>
  <div class="products_item">
    <div class="content__img">
      <svg-icon-qouting  :data="product.layout_image || product.icon"/>
    </div>
    <div class="d-flex w-100">
    <div style="width: 100%">
      <h5 class="item__title"><p>{{product.name}} </p></h5>
      <div v-for="(value, propertyName) in product.required" >
        <p v-if="propertyName == 'Width' ||
        propertyName == 'Drop' ||
        propertyName == 'Style' ||
        propertyName == 'Grade' ||
        propertyName == 'Type' ||
        propertyName == 'Frame Colour' ||
        propertyName == 'Insert Colour' ||
        propertyName == 'Location'">
          <span>{{ propertyName }} - </span>{{value}}
        </p>
      </div>
<!--      <p>-->
<!--        <span>Item code - </span>{{product.code}}-->
<!--      </p>-->
    </div>
    <div class="item__second">
      <div v-for="(value, propertyName) in product.required" >
        <p v-if="propertyName != 'Width' &&
        propertyName != 'Drop' &&
        propertyName != 'Style' &&
        propertyName != 'Grade' &&
        propertyName != 'Type' &&
        propertyName != 'Frame Colour' &&
        propertyName != 'Insert Colour' &&
        propertyName != 'Location' &&
        !propertyName.includes('Out of Square')">
          <strong>{{ propertyName }}: </strong>
          <span v-html="value"></span>
        </p>
      </div>
      <p v-for="(value, propertyName) in product?.optional">
          <strong>{{ propertyName }}: </strong>
          <span v-html="value"></span>
      </p>
    </div>
  </div>
  </div>
</template>

<script>

export default ({
  props: ['product', 'isMobile'],
  setup(props, {emit}) {
    return {
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.content {
  &__img {
    margin-right: 10px;
    font-size: 80px;
    width : 65px;
    ::v-deep .icons__wrapper {
      display: flex;
    }
  }
}
.products_item {
    padding: 15px 30px;
    display: flex;
    color: #1C2833;
    border: 1px solid rgba(11, 53, 83, 0.2);
    img {
      width: 65px;
      height: 80px;
      padding-top: 5px;
      object-fit: contain;
      object-position: center;
      margin-right: 30px;
    }
    p {
      padding-bottom: 5px;
      font-size: 13px;
      line-height: 140%;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
    .item {
      &__title {
        font-size: 15px;
        font-weight: bold;
        display: flex;
        width: 100%;
        justify-content: space-between;
        text-transform: uppercase;
      }
      &__second {
        margin-left: auto;
        width: 240px;
        flex-shrink: 0;
      }
    }
  }
@media (max-width: 768px) {
  .products_item {
    padding: 16px 20px;
    border: none;
    border-top: 1px solid rgba(11, 53, 83, 0.2);
    >div {
      flex-direction: column;
    }
    img {
      margin-right: 15px;
    }

    p {
      padding-bottom: 2px;
    }

    .item {
      &__title {
        font-size: 13px;
      }
      &__second {
        margin-left: 0;
        width: auto;
      }
    }
  }
}
</style>
