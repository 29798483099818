<template>
  <div
    class="dropdown_menu">
    <svg-icon name="regular/ellipsis-h"/>
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "DropdownMenu.vue"
}
</script>

<style scoped lang="scss">
.dropdown_menu {
  ::v-deep .simple_dropdown {
    border-radius: 2px;
    border-bottom: 0;
    padding: 0;
    left: 0;
    right: auto;
    > * {
      padding: 14px 20px;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(11, 53, 83, 0.2);
    }
  }
}
@media (max-width: 768px) {
  .dropdown_menu {
    ::v-deep .simple_dropdown {
      right: 0;
      left: auto;
    }
  }
}
</style>
