<template>
  <SimpleModal class="accept_modal"
               :class="isExpired && 'accept_modal--expired'"
               @close="$emit('close')"
               v-if="!loading"
               :close-text="'Back to leads'"
  >
    <div class="accept_modal__header">
      <slot name="header">
        <div class="d-flex">
          <h4 class="header__title">Accept the lead</h4>
          <LeadsStatus
            @expired="isExpired = true"
            :time="leadItem.expiry_date_time"
          />
        </div>
        <div class="header__info">
          <h5 v-if="leadItem.suburb || leadItem.postcode" :title="leadItem.suburb +' '+ leadItem.postcode" class="info__title" >{{ leadItem.suburb }} {{ leadItem.postcode }}</h5>
          <div class="d-flex">
            <div class="info__form">
              <div class="d-flex align-items-center " style="padding-bottom: 10px; position: relative">
                <svg-icon name="regular/coins"/>&nbsp;
                Estimated price: {{$filters.formatMoney(priceValue)}}
                <!--                <div class="info__label">Estimated price: {{$filters.formatMoney(priceValue)}}</div>-->
                <!--                <Input-->
                <!--                    :small="true"-->
                <!--                    class="offer_input font-weight-bold"-->
                <!--                    :value="priceValue"-->
                <!--                    @input="changePriceValue"-->
                <!--                />-->
                <!--                <LinkReturn v-if="+priceValue != +priceValueDefault" class="info__return" @click="priceValue = +priceValueDefault">-->
                <!--                  <svg-icon name="solid/undo"/>-->
                <!--                  <p>Price default</p>-->
                <!--                </LinkReturn>-->
              </div>
              <div class="align-items-center d-none" >
                <div class="info__label">Price list:</div>
                <SelectDropdown
                  :list="priceList"
                  :placeholder="priceList[0]"
                  :list-is-object="true"
                  type="id"
                  :small="true"
                  :selected="chosenPrice"
                  @select="choosePrice"
                  >
                </SelectDropdown>
              </div>
            </div>
            <div class="info__elements">
              <div class="d-flex align-items-center">
                <svg-icon name="regular/tools"/>
                Installation: {{ $filters.formatMoney(leadItem.installation_fee) }}
              </div>
              <div class="d-flex align-items-center">
                <svg-icon name="solid/user"/>
                Profit(Est.): {{ $filters.formatMoney(profitValue) }} ({{ profitPcnt }}%)
              </div>
            </div>
          </div>
        </div>
        <h5 class="header__number">Number of items: {{ leadItem.items?.length }}</h5>
      </slot>
    </div>
    <div class="accept_modal__body">
      <slot name="body">
        <div class="accept_modal__container" :class="priceValue > priceValueDefault ? 'accept_modal__container--active': ''">
          <div class="accept_modal__content">
            <ProductItem v-for="item in leadItem.items"
                         :product="item"
            ></ProductItem>
          </div>
        </div>
      </slot>
    </div>
    <div class="accept_modal__footer">
      <slot name="footer">
        <div v-if="+priceValue > +priceValueDefault">
          <div class="footer__note">Please add note for your customer</div>

          <ErrorField
            :is-error="isErrorTextarea"
            text-error="The note is required"
          >
          <Textarea
            placeholder="Please add note for your customer"
            @input="changeNoteValue"
            line="3"
          ></Textarea>
          </ErrorField>
        </div>
        <ButtonLeads @click="acceptLead">
          <svg-icon name="solid/check"/>
          {{+priceValue <= +priceValueDefault ? 'Accept' : 'Make An Offer'}}
        </ButtonLeads>
      </slot>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import ButtonLeads from "@/components/buttons/ButtonMain";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import SimpleModal from "@/components/modals/SimpleModal"
import LinkReturn from "@/components/links/LinkReturn";
import Input from "@/components/inputs/Input";
import Textarea from "@/components/inputs/Textarea";
import LeadsStatus from "@/components/leads/LeadsStatus";
import ProductItem from "@/components/jobs/ProductItem";
import {useStore} from "vuex";
import {changePriceForm} from '../../helpers/aroundPrice';
import {dateCalcFormat} from "@/helpers/datetime";
import store from "@/store/store";
import { useRouter } from "vue-router";


export default ({
  components: {
    ButtonLeads,
    SelectDropdown,
    LinkReturn,
    SimpleModal,
    Input,
    Textarea,
    LeadsStatus,
    ProductItem
  },
  props: ['id'],
  emits: ['close'],
  setup(props, {emit}) {
    const store = useStore()
    const router = useRouter()
    const priceList = computed(() => store.getters['leads/priceListFull'])
    const isExpired = ref(false)
    const leadItem = computed(() => store.getters['leads/leadItem'])
    const isErrorTextarea = ref(false)
    const noteValue = ref('')
    const loading = ref(false)
    const profitValue = computed(() => {
      return priceValue.value - leadItem.value.cost
    })
    let priceValue = computed({
      get: () => store.getters['leads/leadProperty']({key: 'amount'}),
      set: value => {
        store.commit('leads/leadProperty', {key: 'amount', value: value})
      }
    })
    const profitPcnt = computed(() => {
      return ((+profitValue.value) / leadItem.value.cost * 100).toFixed(2)
    })


    let priceValueDefault = ref('')
    const isError = ref(false)
    const priceDefault = ref(true)
    const defaultPriceList = ref(null)
    const chosenPrice = ref({})

    let choosePrice = async (price) => {
      chosenPrice.value = price
      await store.dispatch('leads/getLeadItemChangedByPriceList', {lead_id:props.id, pricelist_id: price.id})
    }
    let changePriceValue = (value) => {
      priceValue.value = value
      leadItem.value.profit_pcnt = 654
    }
    let changeNoteValue = (value) => {
      isErrorTextarea.value = false
      noteValue.value = value
    }
    let acceptLead = async () => {
      if (+priceValueDefault.value !== +priceValue.value && noteValue.value.length < 1) {
        isErrorTextarea.value = true
      }
      else {
        const type = (+priceValueDefault.value >= +priceValue.value ? 'accepted' : 'make_an_offer')

        let parameters = {
          "type": type,
          "amount": +priceValue.value,
          "profit_pcnt": leadItem.profit_pcnt,
          "profit_amt": leadItem.profit_amt,
          "note" : noteValue.value,
          "pricelist_id": store.getters['leads/priceListElementValue'](chosenPrice.value.id),
        }
        await store.dispatch('leads/changeLeadsStatus', {
          id: props.id, status: 'mark-as-accepted-or-offer-made',
          parameters: parameters
        } )
        if (type === 'accepted'){
          console.log(leadItem.value)
          router.push({name: 'job', params: {
                        'id' : leadItem.value.container_id
                      }
          })
        }
        emit('close')
      }
    }
    let priceCalc = (price) => {
      return changePriceForm(price);
    }
    let dateCalc = (date) => {
      return date
    }
    let dateFormat = (date) => {
      return dateCalcFormat(date)
    }
    onMounted(async () => {
      store.commit('leads/resetLeadItem')
      loading.value = true
      store.commit('preloader/preloader', true);
      await (store.dispatch('leads/getPriceList'),
            store.dispatch('leads/getLeadItem', props.id)
      )
      loading.value = false
      store.commit('preloader/preloader', false);
      defaultPriceList.value = priceList.value.find( (x) => x.is_default)
      if(defaultPriceList.value) {
        chosenPrice.value =  {name:defaultPriceList.value.name,id:defaultPriceList.value.id}
      }
      priceValueDefault.value = leadItem.value.amount
    })
    return {
      changePriceValue,
      choosePrice,
      chosenPrice,
      leadItem,
      isError,
      isExpired,
      priceList,
      priceValue,
      priceValueDefault,
      priceDefault,
      acceptLead,
      priceCalc,
      dateCalc,
      dateFormat,
      noteValue,
      changeNoteValue,
      isErrorTextarea,
      loading,
      profitValue,
      profitPcnt
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

::v-deep {
  .textarea_component {
    min-height: unset;
    padding: 10px;
    font-size: 14px;
  }
}
.accept_modal {
  ::v-deep .simple_modal__container {
    padding: 0;
    width: auto;
    max-width: 930px;
    position: relative;
    &:before {
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 48px;
      font-weight: bold;
      font-family: Sansation;
      width: 100%;
      height: 100%;
      z-index: 5;
      color: #1C2833;
      backdrop-filter: blur(6px);
    }
  }
  &--expired {
    ::v-deep .simple_modal__container {
      &:before {
        color: rgb(192, 57, 43);
        content: 'Expired';
      }
    }
  }
  &__header {
    padding: 20px 20px 12px;
    color: #1C2833;
    box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
    .header {
      &__title {
        font-weight: bold;
        padding-right: 20px;
        font-size: 25px;
        line-height: 100%;
      }
      &__info {
        padding: 20px 20px 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 10px;
        background: rgba(11, 53, 83, 0.07);
        border-radius: 2px;
        .offer_input {
          width: 170px;
        }
        .info {
          &__title {
            padding-bottom: 10px;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 19px;
          }
          &__return {
            position: absolute;
            // top: -22px;
            // left: 90px;
            right: 0;
            bottom: -17px;
          }
          &__label {
            flex-shrink: 0;
            width: 90px;
          }
          &__form {
            color: rgba(28, 40, 51, 0.7);
            padding-right: 50px;
            border-right: 1px solid rgba(11, 53, 83, 0.2);
            margin-right: 20px;
            font-size: 15px;
            ::v-deep .select_dropdown {
              width: 170px;
            }
          }
          &__elements {
            color: rgba(28, 40, 51, 0.7);
            > div {
              padding-bottom: 10px;
            }
            span {
              margin-right: 6px;
              font-size: 15px;
            }
          }
        }

      }
      &__number {
        padding-top: 10px;
        font-weight: bold;
        font-size: 19px;
      }
    }
  }
  &__container {
    height: calc(100vh - 553px);
    overflow-y: scroll;
    &--active {
      height: calc(100vh - 645px);
    }
    > div {
      height: auto;
    }
  }
  &__footer {
    margin-top: auto;
    padding: 10px 40px;
    background: linear-gradient(0deg, rgba(11, 53, 83, 0.07), rgba(11, 53, 83, 0.07)), #FFFFFF;
    box-shadow: 0px -3px 3px rgba(28, 40, 51, 0.2);
    border-radius: 0 0 2px 2px;
    .footer {
      &__note {
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 19px;
      }
    }
    ::v-deep button {
      height: 35px;
      // margin-top: 15px;
    }
  }
}
@media (max-width: 768px) {
  .accept_modal {
    ::v-deep .simple_modal__container {
      width: 100%;
    }
    &__header {
      padding: 0;
     > .d-flex {
       padding: 10px 20px;
       align-items: center;
       height: 60px;
     }
      .header {
        &__title {
          font-weight: bold;
          padding-right: 0;
          margin-right: auto;
          font-size: 21px;
        }
        &__info {
          padding: 20px;
          margin-top: 0px;
          border-radius: 0;
          >.d-flex {
            flex-direction: column;
          }
          .offer_input {
            // width: 100%;
          }
          .info {
            &__title {
              padding-bottom: 10px;
              font-size: 15px;
            }
            &__label {
              width: 70px;
            }
            &__return {
              left: auto;
              right: 0;
            }
            &__form {
              padding-right: 0;
              border-right: 0;
              margin-right: 0;
              padding-bottom: 10px;
              margin-bottom: 12px;
              font-size: 13px;
              border-bottom: 1px solid rgba(11, 53, 83, 0.2);
              ::v-deep .select_dropdown {
                width: 100%;
              }
            }
            &__elements {
              > div {
                padding-bottom: 10px;
              }
            }
          }

        }
        &__number {
          padding: 0 20px;
          display: flex;
          align-items: center;
          height: 40px;
          font-size: 15px;
          background: #FFFFFF;
        }
      }
    }
    &__container {
      height: 190px;
      &--active {
        height: 0;
      }
    }
    &__footer {
      margin-top: auto;
      padding: 20px;
      .footer {
        &__note {
          font-size: 15px;
        }
      }
      textarea {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
