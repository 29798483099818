<template>
  <section>
      <LeadsList></LeadsList>
  </section>
</template>

<script>

import { ref } from 'vue'
import { useStore } from 'vuex'
import LeadsList from '@/components/leads/LeadsList'

export default {
  components: {
    LeadsList
  },
  setup() {

  }
};
</script>

<style scoped>

</style>
