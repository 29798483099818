<template>
  <SimpleModal class="reject_modal" @close="$emit('close')">
    <h4 class="reject_modal__header">
      <slot name="header">
        Rejection of the lead
      </slot>
    </h4>

    <div class="reject_modal__body">
      <slot name="body">
        <div class="body__notification">
          <svg-icon name="light/exclamation-circle"/>
          <div>
            Please note: this action cannot be reversed. Once you reject the lead you will not be able to see it again
          </div>
        </div>
        <ErrorField
          :is-error="isError"
          text-error="Please select the reason for refusal"
        >
          <SelectDropdown
            :list="reasons"
            placeholder="Select Reject Reason"
            @select="chooseReason"
            :selected="chosenReason"
          >
          </SelectDropdown>
        </ErrorField>
        <ErrorField
          :is-error="isErrorTextarea"
          :text-error="(!changeNoteValue || changeNoteValue ==='')  ? 'The note is required' : 'The reject reason must be at least 3 characters.'"
        >
          <Textarea v-if="chosenReason === 'Other reason'"
                    @input="changeNoteValue"
                    minlength="3"
                    placeholder="Here will be the text of the reason for refusal"></Textarea>
        </ErrorField>
      </slot>
    </div>
    <div class="reject_modal__footer">
      <slot name="footer">
        <ButtonLeads @click="rejectLead">
          <svg-icon name="solid/times"/>
          Reject
        </ButtonLeads>
      </slot>
    </div>
  </SimpleModal>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import ButtonLeads from "@/components/buttons/ButtonMain";
import SelectDropdown from "@/components/dropdown/SelectDropdown";
import SimpleModal from "@/components/modals/SimpleModal";
import Textarea from "@/components/inputs/Textarea";
import {useStore} from "vuex";

export default ({
  components: {
    ButtonLeads,
    SelectDropdown,
    SimpleModal,
    Textarea
  },
  props: ['id'],
  emits: ['close'],
  setup(props, {emit}) {
    const store = useStore()
    const reasons = computed(() => store.getters['leads/rejectionReasons'])
    const isError = ref(false)
    const isErrorTextarea = ref(false)
    const chosenReason = ref(null)
    const noteValue = ref('')
    let chooseReason = (reason) => {
      isError.value = false
      isErrorTextarea.value = false
      chosenReason.value = reason
    }
    let changeNoteValue = (value) => {
      isErrorTextarea.value = false
      noteValue.value = value
    }
    let rejectLead = async () => {
      if(!chosenReason.value) {
        isError.value = true
      } else if (chosenReason.value === 'Other reason' && noteValue.value.length < 3) {
        isErrorTextarea.value = true
      } else {
        await store.dispatch('leads/changeLeadsStatus', {id: props.id, status: 'mark-as-rejected',
          parameters: {
            reject_reason: chosenReason.value === 'Other reason' ? noteValue.value : chosenReason.value
          }} )
        emit('close')
      }
    }
    onMounted(async () => {
      await store.dispatch('leads/getRejectionReasons')
    })
    return {
      chosenReason,
      chooseReason,
      noteValue,
      changeNoteValue,
      rejectLead,
      isError,
      isErrorTextarea,
      reasons
    }
  }
})
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";

.reject_modal {
  &__header {
    font-weight: bold;
    font-size: 25px;
    line-height: 100%;
    color: #1C2833;
    padding-bottom: 30px;
  }
  &__body {
    .body {
      &__notification {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-bottom: 32px;
        color: rgba(28, 40, 51, 0.7);
        letter-spacing: 0.02em;
        span {
          color: rgba(192, 57, 43, 0.8);
          margin-right: 10px;
          font-size: 23px;
        }
      }
    }
    textarea {
      margin-top: 20px;
    }
  }
  &__footer {
    padding-top: 30px;
    margin-top: auto;
    ::v-deep button {
      width: 176px;
      height: 40px;
    }
  }
}
@media (max-width: 768px) {
  .reject_modal {
    &__header {
      font-size: 21px;
      padding-bottom: 20px;
    }
    &__body {
      .body {
        &__notification {
          font-size: 13px;
          span {
            margin-right: 6px;
            font-size: 23px;
          }
        }
      }
    }
    &__footer {
      padding-top: 40px;
      ::v-deep button {
        width: 100%;
      }
    }
  }
}

</style>
