<template>
  <textarea class="textarea_component" @input="$emit('input', $event.target.value )" :placeholder="placeholder"></textarea>
</template>

<script>
import { reactive, ref, computed } from 'vue'
export default {
  components: {
  },
  props: ['placeholder', 'small'],
  emits: ['input'],
  setup(props, { emit }) {
    let selected = ref('')
    return {
      selected
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.textarea_component {
  min-height: 150px;
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(28, 40, 51, 0.3);
  border-radius: 2px;
  font-family: $helvetica;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(28, 40, 51, 0.7);
}
@media (max-width: 768px) {
  .textarea_component {
    font-size: 13px;
    line-height: 120%;
  }
}
</style>
