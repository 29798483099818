<template>
    <Sorting
      :items="leadsAmount"
      :show-quantity="true"
      title="My Leads"
      placeholder="Type to search"
      :sorting="sorting"
      :grouping = "grouping"
      @sorting="sortLeads"
      @grouping="groupLeads"
      @search="searchLeads"
    ></Sorting>
  <div class="overflow-hidden position-relative d-flex flex-column leads_scroll">
    <div ref="leads_scroll" style="height: 100%; overflow-y: auto;" @scroll="loadMore">
      <div v-if="group">
        <div v-for="group in leadsList" class="leads_list container">
          <div class="leads_list__region"><p>{{ group.group_name }}</p></div>
          <LeadsCard v-for="lead in group.group_values" :lead="lead" class="leads_list__item"/>
          <div v-if="(group.group_values?.length+1) % 3 == 0" class="leads_list__item"></div>
        </div>
      </div>
      <div v-else>
        <div  class="leads_list container">
<!--          <div class="leads_list__region"><p>{{ group.group_name }}</p></div>-->
          <LeadsCard v-for="lead in leadsList" :lead="lead" class="leads_list__item"/>
          <div v-if="(leadsList.length+1) % 3 == 0" class="leads_list__item"></div>
        </div>
      </div>

      <div v-if="loading" :class="loading && loading != 'scroll' ? 'leads_list__preloader': ''" class="d-flex justify-content-center py-3 flex-shrink-0">
        <PreloaderSmall/>
      </div>
      <div v-if="!loading && leadsList.length < 1" class="leads_list__absence container">
        <p>Unfortunately, there are no active leads at this time :(</p>
        <p>Try to check a little later.</p>
      </div>
      <div v-if="isScroll" class="leads_list__scroll" @click="toTop">
        <img src="../../assets/img/svg/arrow-up.svg">
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed, watch } from 'vue'
import LeadsCard from "@/components/leads/LeadsCard";
import Sorting from "@/components/sorting/Sorting";
import infiniteScroll from "vue-infinite-scroll";
import PreloaderSmall from "@/components/PreloaderSmall";
import { useStore } from 'vuex'

export default ({
  components: {
    LeadsCard,
    Sorting,
    infiniteScroll,
    PreloaderSmall
  },
  setup() {
    const store = useStore()
    const leads_scroll = ref(null)
    const isScroll = ref(false)
    const sorting = reactive([
      {
        name: 'By date',
        sort: 'by_date'
      },
      {
        name: 'By price',
        sort: 'by_price'
      },
      {
        name: 'By profit',
        sort: 'by_profit'
      },
    ])

    const grouping = reactive([
        {
          name: 'By suburb',
          group_by: 'by_suburb'
        },
    ])
    const leadsList = computed(() => store.getters['leads/leadsList'])
    const leadsAmount = computed(() => store.getters['leads/leadsAmount'])
    const sort = computed(() => store.getters['leads/leadsSorting'])
    const group = computed(() => store.getters['leads/leadsGrouping'])
    const search = computed(() => store.getters['leads/leadsSearch'])
    const loading = ref(true)
    watch(sort, (sort, prevSort) => {
      getLeads()
    })
    watch(group, (search, prevSearch) => {
      getLeads()
    })
    watch(search, (search, prevSearch) => {
      getLeads()
    })
    const sortLeads = async (sort, direction) => {
      store.commit('leads/leadsGrouping',null)
      await store.commit('leads/leadsSorting', direction ? sort.sort :`-${sort.sort}`)
    }
    const groupLeads = async (group, direction) => {
      store.commit('leads/leadsSorting',null)
      await store.commit('leads/leadsGrouping', direction ? group.group_by :`-${group.group_by}`)
    }
    const searchLeads = (search) => {
      store.commit('leads/leadsSearch', search)
    }
    const getLeads =  _.debounce(async () => {
      loading.value = true;
      store.commit('leads/resetLeads')
      await store.dispatch('leads/getLeadsList')
      loading.value =  false
    }, 100);
    const loadMore = async (e) => {
      let { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop > clientHeight * 2) {
        isScroll.value = true
      } else {
        isScroll.value = false
      }
      if ((loading.value === false) && (scrollTop + clientHeight >= scrollHeight - 400)) {
        loading.value = 'scroll';
        await store.dispatch('leads/getLeadsList')
        loading.value =  false
      }
    }
    const toTop = async (e) => {
      leads_scroll.value.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    onMounted(async () => {
      await store.dispatch('leads/getLeadsCount')
      store.commit('leads/leadsGrouping',`by_suburb`)
      //store.commit('leads/leadsSorting',`by_date`)
      store.commit('leads/leadsSearch',` `)
      getLeads()
    })
    return {
      leadsList,
      leadsAmount,
      sorting,
      loadMore,
      searchLeads,
      sortLeads,
      groupLeads,
      loading,
      getLeads,
      toTop,
      leads_scroll,
      isScroll,
      grouping,
      group
    }
  },
})
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.leads_list {
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;
 &__region {
   width: 100%;
   padding-bottom: 20px;
   text-transform: uppercase;
   font-weight: bold;
   font-size: 21px;
   line-height: 100%;
   text-transform: uppercase;
   padding-top: 10px;
   position: relative;
   color: rgba(11, 53, 83, 0.5);

   p {
     position: relative;
     display: inline-block;
     padding: 0 20px;
     background-color: white;
     top: -10px;
     left: -20px;
   }
   &:before {
     position: absolute;
     content: '';
     height: 1px;
     left: calc((100% - 100vw) / 2);
     width: calc(100vw - 6px);
     background-color: rgba(11, 53, 83, 0.3)
   }
 }
 &__item {
   width: 33%;
   max-width: 350px;
   margin-bottom: 30px;
 }
  &__preloader {
    margin-top: 25vh;
  }
  &__absence {
    font-size: 21px;
    line-height: 140%;
    padding-top: 24px;
    color: rgba(28, 40, 51, 0.7);
  }
  &__scroll {
    position: absolute;
    bottom: 45px;
    left: calc((100vw - 1150px) / 2 + 1165px);
    width: 40px;
    height: 80px;
    background: rgba(149, 174, 192, 0.2);
    color: rgba(11, 53, 83, 0.3);
    border-radius: 2px;
    cursor: pointer;
    transition-duration: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgba(149, 174, 192, 0.4);
    }
  }
}
.leads_scroll {
  height: calc(100vh - 227px);
  position: relative;
}
@media (max-width: 1024px) {
  .leads_list {
    &__item {
      width: 50%;
    }
  }
}
@media (max-width: 768px) {
  .leads_scroll {
    height: calc(100vh - 232px)
  }
  .leads_list {
    padding: 0;
    justify-content: center;
    margin-bottom: 15px;
    &__region {
      padding-bottom: 10px;
      font-size: 15px;
      color: #1C2833;
      padding-top: 10px;
      p {
        top: -6px;
        left: 0px;
        padding-left: 20px;
        padding-right: 10px;
      }
      &:before {
        width: calc(100vw - 4px);
      }
    }
    &__item {
      width: 100%;
      max-width: 400px;
      margin-bottom: 10px;
    }
    &__absence {
      padding-top: 0;
      font-size: 15px;
    }
    &__scroll {
      bottom: 0px;
      left: auto;
      right: 0;
      width: 30px;
      height: 60px;
      background: rgba(11, 53, 83, 0.25);
    }
  }
}
</style>
